import React from "react";
import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";

const progressInfo = "Costs is determined by stock($5/unit) and backlog($25). The slice in Pie-charts represents your partition of the supply-chain combined costs.";
const incomingInfo = "This is the order your customer placed last week. This amount is to be drawn from stock. If stock is not sufficient to this amount, the lacking amount will be deducted from stock and added to backlog.";
const botInfo = "Ghost player strategy: Place order based on customer order with a random deviation of +- 2 pcs."
const InfoTip = ({ subject }) => {
  const setInfo = () => {
    switch (subject) {
      case "progress":
        return progressInfo;
      case "incoming":
        return incomingInfo;
      case "botInfo":
        return botInfo;
      default:
        return "info";
    }
  };

  return (
    <Tooltip title={setInfo()} arrow>
      <InfoIcon sx={{ color: "#80c6e7", width: 20, height: 20 }} />
    </Tooltip>
  );
};

export default InfoTip;
